@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Lato:wght@300;400&family=Poppins:wght@700;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "system-ui"
}

html, body, #root {
  height: 100%;
  width: 100%;
}

#root {
  box-shadow: inset 0 0 6vh 6vh #00518745;
}

.about-container {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 920px;
  min-width: 680px;
  max-width: 1260px;
  overflow: hidden;
}

.about-container * {
  font-family: 'Lato', sans-serif;
}

.about-container h1 {
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: -0.1rem;
  margin-bottom: 1rem;
}

.about-container p {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 300;
  margin: 1rem;
}

.about-container pre {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px 20px;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  overflow-x: auto;  /* in case the content is wider than the container */
  white-space: pre-wrap; /* to ensure text wraps */
  word-spacing: normal;
  word-break: normal;
  word-wrap: break-word;
  font-weight: 600;
  color: #333;
  line-height: 1.5;
  tab-size: 4;
}

.tags-list {
  display: flex;
  list-style-type: none;
}

.tags-list li {
  margin-right: 0.5rem;
}

.tags-list li:not(:last-child)::after {
  content: '·';
  font-weight: 600;
  margin-left: 0.5rem;
}

.dots-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.dots-loading div {
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem;
  background-color: #333333;
  border-radius: 50%;
  animation: dotsLoading 1.2s infinite;
}

.dots-loading div:nth-child(1) {
  animation-delay: 0s;
}

.dots-loading div:nth-child(2) {
  animation-delay: 0.4s;
}

.dots-loading div:nth-child(3) {
  animation-delay: 0.8s;
}

.made-with {
    position: fixed;
    bottom: 2rem;
		font-weight: 300;	
		font-size: 0.8rem;
		color: #00141a;
}

.made-with a {
  display: inline-block;
  text-decoration: none;
  color: #3f002e;
}

@keyframes dotsLoading {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.5);
  }
  40% {
    transform: scale(1);
  }
}